import _, {last} from 'underscore';
import {getToken} from './auth';

import {api} from 'universal-navbar/grab';
import {request} from 'universal-navbar/request';
import {extractHostname} from "./helpers";

const url = window.grabApiUrl || 'https://api.grab.live';


function deals(clientToken, callback) {
    callback = _.last(arguments);

    if (!clientToken && !getToken()) {
        return callback(null, []);
    }
    request('GET', [url, '/deal/show'].join(''), {
        token: clientToken || getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.deals);
    });
}

function dealBuy(data, callback) {
    request('POST', [url, '/deal/buy'].join(''), _.extend(data, {
        token: getToken()
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.deal);
    });
}

function dealCancel(deal, callback) {
    request('POST', [url, '/deal/cancel'].join(''), {
        deal,
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.deal);
    });
}

function dealUpdate(data, callback) {
    request('POST', [url, '/deal/update_domain'].join(''), _.extend(data, {
        token: getToken()
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.deal);
    });
}

function dealSummary(callback) {
    request('GET', [url, '/deal/summary'].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.summary);
    });
}

function leaderboardLeaders(data, callback) {
    /*
    * Parameters:
id - the number or code of the leaderboard (it doesn't actually take record IDs but I'll fix that)
sort - defaults to rank, probably best to leave that alone
count - default is 20, feel free to increase that; it's pretty quick
    * */
    request('GET', [url, '/kred/leaderboard'].join(''), _.extend(data, {
        token: getToken()
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.leaders);
    });
}

function importTwitterFriends(callback) {
    request('GET', [url, '/user/import_friends'].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, {
            status: res.status
        });
    });
}

function importTwitterFollowers(callback) {
    request('GET', [url, '/user/import_followers'].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, {
            status: res.status
        });
    });
}

function getTwitterData(handle, callback) {
    request('GET', [url, '/user/links/', handle].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, {
            fullname: res.user && res.user.name,
            avatar: 'https://avatars.socialos.net/twitter/' + (res.user && res.user.screen_name),
            background: res.user && res.user.profile_banner_url || res.user && res.user.profile_background_image_url_https || '',
            links: _.first(_.map(res.posts, function (post, key) {
                return {
                    id: key,
                    image: post.user && post.user.profile_banner_url || 'https://avatars.socialos.net/twitter/' + (post.user && post.user.screen_name),
                    link: post.entities && post.entities.urls && post.entities.urls[0] && post.entities.urls[0].url,
                    title: post.text
                    // buttoncolor: post.user && post.user.profile_background_color,
                    // textcolor: post.user && post.user.profile_text_color
                }
            }), 5),
            socials: {
                twitter: 'https://twitter.com/' + (res.user && res.user.screen_name)
            }
        });
    });
}

function getContacts(data, callback) {
    request('GET', [url, '/contact/find2'].join(''), _.extend(data, {
        token: getToken()
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.contacts);
    });
}

function getContactGroups(callback) {
    request('GET', [url, '/contact/optgroups'].join(''), {
        token: getToken()
    }, callback);
}

function getGroupsContacts(groupId, callback) {
    request('GET', [url, '/contact/find'].join(''), {
        groups: groupId,
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.contacts);
    });
}

function setAdmin(admin, callback) {
    request('GET', [url, '/admin/add'].join(''), {
        admin,
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res);
    });
}

function removeAdmin(admin, callback) {
    request('GET', [url, '/admin/remove'].join(''), {
        admin,
        token: window.clientToken || getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res);
    });
}

function getAdmins(callback) {
    request('GET', [url, '/admin/admins'].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.admins);
    });
}

function getClients(callback) {
    request('GET', [url, '/admin/users'].join(''), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.users);
    });
}

function getClientToken(clientId, callback) {
    request('GET', [url, '/admin/token'].join(''), {
        admin: clientId,
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.token);
    });
}

function getVisitorStats(domainName, interval, callback) {
    var data = {
        domains: domainName,
        token: getToken()
    };

    if (interval) {
        data.interval = 'daily';
    }

    request('GET', [url, '/count/stats'].join(''), data, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, {
            stats: res.stats,
            clicks: res.clicks || 0,
            views: res.views || 0
        });
    });
}

function getTopVisitors(domainName, callback) {
    var data = {
        domain: domainName,
        token: getToken()
    };

    request('GET', [url, 'visitor', domainName].join('/'), {
        token: getToken()
    }, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.visitors);
    });
}

function getLinkStats(id, interval, callback) {
    var data = {
        url: id,
        token: getToken()
    };

    if (interval) {
        data.interval = 'daily';
    }

    request('GET', [url, '/count/stats'].join(''), data, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, {
            stats: res.stats,
            clicks: res.clicks || 0,
            views: res.views || 0
        });
    });
}

function getDomainEvents(data, callback) {
    request('GET', [url, '/event/feed'].join(''), data, function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res.events);
    });
}

function instagramSilo(data, callback) {
    /*
        https://api.grab.live/silo/instagram

        Parameters:

        name - Instagram username (@name)
        foreign_id - Instagram numeric ID (if we have that)
        tags - if we want to tag it, you can add tags here
        data - either an embedded object or a string containing the JSON encoded data
    * */

    request('POST', [url, '/silo/instagram'].join(''), data, function (error, res) {
        return callback();
    });

}

function domainFind(domain, callback) {
    request('GET', [url, '/domain/find'].join(''), {
        domain,
        token: getToken()
    }, function (error, res) {
        return callback(null, res.domain || {});
    });
}

export {
    api,

    deals,
    dealBuy,
    dealCancel,
    dealUpdate,
    dealSummary,

    leaderboardLeaders,

    importTwitterFriends,
    importTwitterFollowers,
    getTwitterData,

    getContacts,
    getContactGroups,
    getGroupsContacts,

    //Agency Admin
    setAdmin,
    removeAdmin,
    getAdmins,
    getClients,
    getClientToken,

    //Analytics
    getVisitorStats,
    getTopVisitors,
    getLinkStats,
    getDomainEvents,

    instagramSilo,

    domainFind
};
