import React from 'react';
import {Router, Switch} from 'react-router-dom';
import {AsyncRoute} from './AsyncRoute';

import {history} from '../js/history';
import {isUserLoggedIn} from "../js/auth";
import {NavBar} from "./NavBar";
import {TabNavigation} from "./TabNavigation";
import {checkIfMobile} from "../js/helpers";

export default function App(props) {
    return (
        <Router history={history}>
            <div className="container">
                {checkIfMobile() ? <TabNavigation/> : <NavBar/>}

                <Switch>
                    {!isUserLoggedIn() ? <AsyncRoute
                        exact={true}
                        path="/"
                        promise={() =>
                            import(/* webpackChunkName: "Edit" */ './Edit')
                        }
                        render={(
                            {match},
                            {default: Edit}
                        ) => (
                            <Edit/>
                        )}
                    /> : <AsyncRoute
                        exact={true}
                        path="/"
                        promise={() =>
                            import(/* webpackChunkName: "Manage" */ './Manage')
                        }
                        render={(
                            {match},
                            {default: Manage}
                        ) => (
                            <Manage/>
                        )}
                    />}

                    <AsyncRoute
                        exact={true}
                        path="/manage"
                        promise={() =>
                            import(/* webpackChunkName: "Manage" */ './Manage')
                        }
                        render={(
                            {match},
                            {default: Manage}
                        ) => (
                            <Manage/>
                        )}
                    />

                    <AsyncRoute
                        exact={true}
                        path="/manage/:domainName"
                        promise={() =>
                            import(/* webpackChunkName: "Edit" */ './Edit')
                        }
                        render={(
                            {match},
                            {default: Edit}
                        ) => (
                            <Edit
                                domainName={match.params.domainName}
                                manage={true}
                            />
                        )}
                    />

                    <AsyncRoute
                        promise={() =>
                            import(/* webpackChunkName: "NotFound" */ './NotFound')
                        }
                        render={({match}, {default: NotFound}) => (
                            <NotFound/>
                        )}
                    />
                </Switch>
            </div>
        </Router>
    );
}
