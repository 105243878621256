import $ from 'jquery';
import { debounce } from 'underscore';
import AirbrakeClient from 'airbrake-js';

let ajax = getAjax;

var airbrake = new AirbrakeClient({
	projectId: 207376,
  	projectKey: 'ce50c13a55770cc202bc8e12308244e3'
});

function getAjax(method, url, data, ajaxOptions) {
	return new Promise((resolve, reject) => {
		const cacheKey = getCacheKey(url, data);
		const cachedResult = method === 'GET' && getCacheItem(cacheKey);

		if (navigator.onLine === false && cachedResult) {
			return resolve(JSON.parse(cachedResult));
		}

		return $.ajax({
			...ajaxOptions,
			type: method,
			dataType: 'json',
			cache: true,
			url: url,
			data: data,
			timeout: cachedResult ? 60000 : undefined,
			success: function(res) {
				if (method === 'GET') {
					setCacheItem(cacheKey, JSON.stringify(res));
				}

				if (res && res.error) {
					airbrake.notify({
						error: ['API error', url].join(' - '),
						params: {
							url: url,
							data: data,
							error: res.error,
							response: res
						}
					});
					reject(res);
				} else {
					resolve(res);
				}
			},
			error: function(res) {
				airbrake.notify({
					error: ['API Error', url].join(' - '),
					params: {
						url: url,
						data: data,
						error: res.error,
						response: res
					}
				});
				if (cachedResult) {
					return resolve(JSON.parse(cachedResult));
				} else if (navigator.onLine === false) {
					reject({
						error: 'offline',
						message: 'You are currently offline. Please try again later.'
					});
				}

				if (res.status === 500) {
					airbrake.notify({
						error: ['500 error', url].join(' - '),
						params: {
							url: url,
							data: data,
							response: res
						}
					});
					reject({
						error: 'error',
						message: 'Something went wrong. Please try again later.'
					});
				} else {
					resolve(res.responseJSON);
				}
			}
		});
	});
}

const cache = loadCache() || {
	history: [],
	data: {}
};

function loadCache() {
	try {
		// TODO: remove this for loop some time after March 2019
		// This is just cleaning up the old style of cache entries
		for (let k in localStorage) {
			if (/^request:/.test(k)) {
				localStorage.removeItem(k);
			}
		}

		const json = localStorage.getItem('requestCache');

		if (json) {
			return JSON.parse(json);
		}
	} catch (e) {}
}

const saveCache = debounce(function() {
	while (cache.history.length > 0) {
		try {
			localStorage.setItem('requestCache', JSON.stringify(cache));
			return true;
		} catch (e) {
			const deleteKey = cache.history.shift();
			delete cache.data[deleteKey];
		}
	}

	return false;
}, 1000);

function addKeyToCacheHistory(key) {
	cache.history = cache.history.filter(k => k !== key);
	cache.history.push(key);
}

function getCacheItem(key) {
	addKeyToCacheHistory(key);
	saveCache();

	return cache.data[key];
}

function setCacheItem(key, value) {
	addKeyToCacheHistory(key);

	cache.data[key] = value;

	saveCache();
}

function getCacheKey(url, data) {
	const dataCopy = Object.assign({}, data);

	delete dataCopy.token;

	// allow for a cache-breaking key,
	// but don't use it for our cache key
	delete dataCopy.nocache;

	// this is used by login for authentication, but has random UUID in it and breaks cache
	delete dataCopy.onlogout;

	return `${url}?${$.param(dataCopy)}`;
}

function request(method, url, data = {}, callback, ajaxOptions = {}) {
	const promise = ajax(method, url, data, ajaxOptions);

	if (callback) {
		promise.then(res => callback(null, res), error => callback(error));
	}

	return promise;
}

// injection for testing
export function mockRequest(handler) {
	if (typeof handler === 'object') {
		const map = handler;

		handler = function(method, url) {
			for (let partial in map) {
				if (url.indexOf(partial) !== -1) {
					return map[partial];
				}
			}
		};
	}

	ajax = async function(method, url, data) {
		const result = handler(method, url, data);

		if (typeof result === 'undefined') {
			console.warn('request unmocked', method, url, data);
		}

		return result;
	};
}

export function unmockRequest() {
	ajax = getAjax;
}

export { request };
