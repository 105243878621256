import {checkToken, getCode} from './model/login';

const GUEST_TOKEN = '734d4bf5-e766-46a9-be21-94035c1343d6';

let token = false;
let clientToken = false;

const tokenCheck = checkToken();

tokenCheck.then(setToken);

function setToken(t) {
	token = window.token = t;
	console.log('Setting Token:', t);
	if (t) {
		$(document.body).trigger('loginCheck:loggedin', t);
	} else {
		$(document.body).trigger('loginCheck:notloggedin');
	}

}

function checkLogin(callback) {
	tokenCheck.then(callback, () => callback(false));
}

function checkCode(callback) {
	getCode().then(callback, () => callback(false));
}


function checkLoginRefresh(callback) {
	checkToken().then(setToken).then(callback, () => callback(false));
}

// expose for other scripts to access (CryptoKred or Engagement Profile, for example)
window.checkLogin = checkLogin;

export function getToken() {
	return clientToken || token || GUEST_TOKEN;
}

function setClientToken(t) {
	console.log('Setting Client Token:', t);
	clientToken = window.clientToken = t;
}

function getClientToken() {
	console.log('Getting Client Token:', clientToken);
	return clientToken;
}

export { setToken, checkLogin, checkLoginRefresh, checkCode, setClientToken, getClientToken };
export { generateUUID } from './model/login';
