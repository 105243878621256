import {extend, isEmpty, first, last} from 'underscore';
import {request} from 'universal-navbar/request';
import {formatUser, getToken} from './auth';

var url = window.grabApiUrl;

function timeDifference(end) {
    var endTime = new Date(end),
        now = new Date(),
        end_utc_timestamp = Date.UTC(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(),
            endTime.getHours(), endTime.getMinutes(), endTime.getSeconds(), endTime.getMilliseconds()) + (navigator.platform === 'iPhone' || navigator.platform === 'iPad' ? now.getTimezoneOffset() * 60000 : 0),
        now_utc_timestamp = now.getTime(),
        diffMs = endTime - now_utc_timestamp,
        diffDays = Math.floor(diffMs / 86400000),
        diffHrs = Math.floor((diffMs % 86400000) / 3600000),
        diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000),
        text = '';

    if (diffMs <= 0) {
        return 'Ended';
    }

    if (diffDays) {
        text += diffDays + 'D ';
    }
    if (diffHrs) {
        text += diffHrs + 'H ';
    }
    if (diffMins) {
        text += diffMins + (diffDays || diffHrs ? 'M' : (diffMins === 1 ? ' MIN' : ' MINS'));
    }

    return text;
}

function isBetweenTimes(start, end) {
    var now = new Date().getTime();

    return now > start && now < end;
}

function dayDifference(end) {
    var endTime = new Date(end),
        now = new Date(),
        end_utc_timestamp = Date.UTC(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(),
            endTime.getHours(), endTime.getMinutes(), endTime.getSeconds(), endTime.getMilliseconds()) + (navigator.platform === 'iPhone' || navigator.platform === 'iPad' ? now.getTimezoneOffset() * 60000 : 0),
        now_utc_timestamp = now.getTime(),
        diffMs = end_utc_timestamp - now_utc_timestamp,
        diffDays = Math.floor(diffMs / 86400000);

    return diffDays;
}

function getExpiryString(timestamp) {
    var day = new Date(timestamp * 1000).getDate(),
        month = new Date(timestamp * 1000).getMonth(),
        monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return [day, monthArr[month]].join(' ');
}

function getDateString(timestamp) {
    var day = new Date(timestamp * 1000).getDate(),
        month = new Date(timestamp * 1000).getMonth(),
        year = new Date(timestamp * 1000).getFullYear(),
        monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return [day, monthArr[month], year].join(' ');
}

function getShortDateString(timestamp) {
    var day = new Date(timestamp * 1000).getDate(),
        month = new Date(timestamp * 1000).getMonth(),
        year = new Date(timestamp * 1000).getFullYear(),
        monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return [day, monthArr[month], year].join(' ');
}

function getShortDayDateString(timestamp) {
    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    };
    const isTomorrow = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() + 1 &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    };

    var date = new Date(timestamp * 1000),
        dayText = date.getDay(),
        day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today = isToday(date),
        tomorrow = isTomorrow(date);

    return !!today ? ['Today', formatAMPM(date).replace(':00 ', '')].join(' ') :
        !!tomorrow ? ['Tomorrow', formatAMPM(date).replace(':00 ', '')].join(' ') : [dayArr[dayText],
            day, monthArr[month],
            formatAMPM(date).replace(':00 ', '')].join(' ');
}


function getYearString(timestamp) {
    var year = new Date(timestamp * 1000).getFullYear();
    return year;
}

function getDateTimeString(timestamp) {
    var hours = new Date(timestamp * 1000).getHours(),
        minutes = new Date(timestamp * 1000).getMinutes();

    return getShortDateString(timestamp) + ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
}

function convertLocalToUTC(timeString) {
    var now_utc = Date.UTC(timeString.getUTCFullYear(), timeString.getUTCMonth(), timeString.getUTCDate(), timeString.getUTCHours(), timeString.getUTCMinutes(), timeString.getUTCSeconds());

    return new Date(now_utc);
}

function convertUTCToLocal(timeString) {
    var dateTimeArray = timeString.split(/-|T|:/),
        utcDate = Date.UTC(parseInt(dateTimeArray[0]), parseInt(dateTimeArray[1] - 1), parseInt(dateTimeArray[2]), parseInt(dateTimeArray[3]), parseInt(dateTimeArray[4]));

    return new Date(utcDate);
}

function expiryCheck(timeString) {
    var end = new Date(timeString).getTime(),
        now = new Date().getTime();

    return end < now; //true = expired
}

function getTimestring(time) {
    var utcDate = convertUTCToLocal(time);
    return new Date(utcDate).getTime();
}

function getExpMonthDay(timestamp) {
    var month = new Date().getMonth() + 2;
    if (!timestamp) {
        return [month, '1'].join('/')
    }
    var day = new Date(timestamp * 1000).getDate();

    return [month, day].join('/')
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes === 0 ? '' : minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ampm;
    if (!minutes) {
        strTime = hours + ampm;
    }
    return strTime;
}

//Saturday, February 29, 2020 at 12:00 AM AEDT
function fullTimeString(timestamp) {
    var dayStr = new Date(timestamp * 1000).getDay(),
        day = new Date(timestamp * 1000).getDate(),
        month = new Date(timestamp * 1000).getMonth(),
        year = new Date(timestamp * 1000).getFullYear(),
        time = new Date(timestamp * 1000).toTimeString(),
        timezone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1].match(/\b(\w)/g).join(''),
        dayArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return [dayArr[dayStr], [monthArr[month], day].join(', '), year, 'at', formatAMPM(new Date(timestamp * 1000)), timezone].join(' ');
}

function isToday(timestamp) {
    var ts = timestamp * 1000;
    var today = new Date().setHours(0, 0, 0, 0);
    var thatDay = new Date(ts).setHours(0, 0, 0, 0);

    return today === thatDay;
}

function isYesterday(timestamp) {
    var ts = timestamp * 1000;
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0);;
    var thatDay = new Date(ts).setHours(0, 0, 0, 0);

    return yesterday === thatDay;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function objectifyForm(formArray) { //serialize data function
    var returnArray = {};
    for (var i = 0; i < formArray.length; i++) {
        var value = formArray[i]['value'];
        returnArray[formArray[i]['name']] = value && value.replace(/(<|>)/g, '');
    }
    return returnArray;
}

function upload(name, content, callback) {
    request('POST', [url, '/file/upload'].join(''), JSON.stringify({
        token: getToken(),
        content: content.replace(/^data:[^;]+;base64,/, ''),
        name: [new Date().getTime(), name].join('_')
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res);
    }, {
        headers: {
            "content-type": "application/json"
        }
    });
}

function updateUser(data, callback) {
    request('POST', [url, '/user/profile'].join(''), extend(data, {
        token: getToken()
    }), function (error, res) {
        if (error || res.error) {
            return callback(error || res.error);
        }
        return callback(null, res);
    });
}

function capitalise(s) {
    if (!s) {
        return '';
    }

    return s.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    }).replace(/ceo$/i, 'CEO').replace(/kred$/i, 'Kred');
}

/**
 * When a user has not supplied a bio / name the name field just appears blank.
 * This function tries to grab any sort of information from the userObject so we can display at least something relating to the user.
 * @param userEntity
 */
function formatDisplayName(userEntity) {
    if (!userEntity || !userEntity.bio) {
        return '';
    }

    if (userEntity.email && userEntity.email === 'placeholder@peoplebrowsr.com') {
        return '\n';
    }

    var isDigitOnly = function (s) {
        return new RegExp(/^\d+$/).test(s);
    };

    var res = '';

    if (!userEntity.bio) {
        if (userEntity.name) {
            res = userEntity.name.split('.')[0];
        } else if (userEntity.screen_name) {
            res = userEntity.screen_name.split('.')[0];
        } else if (userEntity.domain) {
            res = userEntity.domain.split('.')[0];
        }
    } else if (userEntity.name === (userEntity.bio && userEntity.bio.name) || isEmpty(userEntity.bio && userEntity.bio.name && userEntity.bio.name.trim())) {
        if (userEntity.bio && !isEmpty(userEntity.email)) {
            res = userEntity.email.split('@')[0];
        } else if (userEntity.home) {
            res = userEntity.home.split('.')[0];
        } else if (userEntity.data && userEntity.data.profile_url) {
            res = userEntity.data.profile_url.split(/(\/\/|\.)/)[2];
        } else if (userEntity.name) {
            res = userEntity.name.split('.')[0];
        } else if (userEntity.domain) {
            res = userEntity.domain.split('.')[0];
        } else {
            res = userEntity.name && userEntity.name.split('.')[0];
        }
    } else if (userEntity.bio && !isEmpty(userEntity.bio.name)) {
        res = userEntity.bio.name;
    } else if (userEntity.bio && !isEmpty(userEntity.bio.displayName)) {
        res = userEntity.bio.displayName;
    } else if (userEntity.bio && !isEmpty(userEntity.email)) {
        res = userEntity.email.split('@')[0];
    } else if (userEntity.bio && !isEmpty(userEntity.name)) {
        res = userEntity.name;
    }

    //res = res.replace(/[0-9]/g, '');
    //res = res.replace('.', ' ');
    //res = res.replace(/[^a-zA-Z0-9 ]/g, '');
    if (res && !!res.match(/^0x[a-fA-F0-9]{40}$/)) {
        res = [res.slice(0, 4), res.slice(-4)].join('...')
    }
    return capitalise(res);
}

function getFirstName(userEntity) {
    var fullname = formatDisplayName(userEntity);

    return fullname && first(fullname.split(' '));
}

function getAvatar(user) {
    // lets use avatars.io for twitter users
    if ((user.bio && user.bio.avatar) || (user.bio && user.bio.avatar_base) || user.avatar) {
        var avatar = (user.bio && user.bio.avatar) || (user.bio && user.bio.avatar_base) || user.avatar;
        var isTwitterAvatar = avatar.match(/https?:\/\/(www\.)?twitter\.com\/(#!\/)?@?([^\/]*)/);
        if (isTwitterAvatar && isTwitterAvatar[3]) {
            return 'https://avatars.socialos.net/twitter/' + isTwitterAvatar[3];
        }
        if (avatar.match('https://avatars.socialos.net/twitter')) {
            return avatar + '/size/original';
        }
        return avatar.replace('//30p8ypma69uhv', '//d30p8ypma69uhv').replace('http://', 'https://');
    }

    if (user.bio && user.bio.at_name) {
        return 'https://avatars.socialos.net/twitter/' + user.bio.at_name;
    }
    // don't ask. some user accounts have this instead of an at_name
    if (user.bio && user.bio.displayName) {
        return 'https://avatars.socialos.net/twitter/' + user.bio.displayName;
    }

    return 'https://d30p8ypma69uhv.cloudfront.net/stream/uploads/53756175b7725d370d9a208f_b91f434779e3f4a5f80d4b2373394d83_defaultAvatar.jpg';
}

function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function getQueryParam(query, name) {
    var regex = new RegExp("[\\?&]" + escapeRegExp(name) + "=([^&#]*)"),
        results = regex.exec(query);

    return !results ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getAllQueryParamsAsObject() {
    var search = location.search.substring(1);
    if (!search) {
        return {};
    }
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

function getIPInfo(callback) {
    request('GET', 'https://ipinfo.io/json', {}, function (error, res) {
        if (error) {
            return callback(error);
        }
        return callback(null, res);
    });
}

function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function checkIfMobile() {
    var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check || $(window).width() <= 768;
}

function checkIfiOSWebKitWebView() {
    var isWKWebview = getQueryParam(location.href, 'isWKWebview') || localStorage.getItem('isWKWebview');
    if (isWKWebview) {
        localStorage.setItem('isWKWebview', isWKWebview);
    }
    if (navigator.platform.substr(0, 2) === 'iP' && !!isWKWebview) {
        var lte9 = /constructor/i.test(window.HTMLElement),
            idb = !!window.indexedDB;

        if ((window.webkit && window.webkit.messageHandlers) || !lte9 || idb) {
            //iOS WKWebview
            return true;
        }
    }
    return false;
}

function isInIframe() {
    return window.self !== window.top;
}

function checkBrowser() {
    //Check if browser is IE
    if (navigator.userAgent.search("MSIE") >= 0) {
        return 'IE';
    }
    //Check if browser is Chrome
    else if (navigator.userAgent.search("Chrome") >= 0) {
        return 'Chrome';
    }
    //Check if browser is Firefox
    else if (navigator.userAgent.search("Firefox") >= 0) {
        return 'Firefox';
    }
    //Check if browser is Safari
    else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
        return 'Safari';
    }
    //Check if browser is Opera
    else if (navigator.userAgent.search("Opera") >= 0) {
        return 'Opera';
    }

    return '';
}

function iOS() {
    return navigator.platform === 'iPhone' || navigator.platform === 'iPad';
}

function macOS() {
    return navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC';
}

function formatStringJoin(arr) {
    var outStr = "";
    if (arr.length === 1) {
        outStr = arr[0];
    } else if (arr.length === 2) {
        //joins all with "and" but no commas
        //example: "bob and sam"
        outStr = arr.join(' and ');
    } else if (arr.length > 2) {
        //joins all with commas, but last one gets ", and" (oxford comma!)
        //example: "bob, joe, and sam"
        outStr = arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
    }
    return outStr;
}

async function getShortUrl(long_url) {
    var res = await request(
        'GET',
        'http://api.bitly.com/v3/shorten?callback=?',
        {
            "format": "json",
            "apiKey": 'R_707c51e22be942258c44dd37d37a4558',
            "login": 'o_3gtfaos4qr',
            "longUrl": long_url
        }
    );

    return res.data.url;

}

function getProfileUrl(domain) {
    if (!domain) {
        return '';
    }

    domain = domain.replace(/^http(s)?:\/\//i, '');

    if (domain.match(/\.c\.kred$/i)) {
        domain = domain;
    } else if (domain.match(/\.kred$/i)) {
        domain = first(domain.split('.'));
    }

    return '/profile/' + domain;
}

function getWalletProfileUrl(domain) {
    if (!domain) {
        return '';
    }
    //
    // domain = domain.replace(/^http(s)?:\/\//i, '');
    //
    // if (domain.match(/\.c\.kred$/i)) {
    //     domain = domain;
    // } else if (domain.match(/\.kred$/i)) {
    //     domain = first(domain.split('.'));
    // }

    return 'https://' + domain + '/wallet';
}


async function formatVCard(coin) {
    var res = await request(
        'GET',
        'https://api.grab.live/user/home',
        {
            user: coin.creator,
            token: getToken()
        }
        ),
        userEntity = formatUser(res),
        vCard = {},
        contact = coin && coin.contact || {},
        fullname = contact.name || formatDisplayName(userEntity),
        avatar = contact.avatar || getAvatar(userEntity);

    vCard.firstName = first(fullname.split(' '));
    vCard.lastName = last(fullname.split(' '));
    vCard.photo = avatar;
    vCard.title = (contact && contact.position) || (userEntity && userEntity.bio && userEntity.bio.title);
    vCard.url = 'https://' + (contact.domain || userEntity.home);
    vCard.note = (contact && contact.description) || (userEntity && userEntity.bio && userEntity.bio.description);
    if (userEntity && userEntity.data && !userEntity.data.phonePrivate) {
        vCard.cellPhone = userEntity && userEntity.bio && userEntity.bio.phone;
    }
    vCard.email = userEntity && userEntity.email;
    vCard.homeAddress = {};
    vCard.homeAddress.stateProvince = userEntity && userEntity.bio && userEntity.bio.state && userEntity.bio.state.toUpperCase();
    vCard.homeAddress.countryRegion = userEntity && userEntity.bio && userEntity.bio.cc && userEntity.bio.cc.toUpperCase();
    vCard.socialUrls = {};
    vCard.socialUrls['facebook'] = contact && contact.facebook;
    vCard.socialUrls['linkedIn'] = contact && contact.linkedin;
    vCard.socialUrls['twitter'] = contact && contact.twitter;
    vCard.socialUrls['youtube'] = contact && contact.youtube;

    return vCard;
}

function addScript(src) {
    const s = document.createElement('script');
    s.async = true;
    s.src = src;
    document.querySelector('script').parentNode.appendChild(s);
}

function extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname.replace(/^www\./, '');
}

function kFormatter(num) {
    if (num > 999999) {
        return (num / 1000000).toFixed(1) + 'M';
    }

    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
}

function getDefaultTemplates() {
    return [{
        id: "1",
        background: 'https://cdn.nft.kred/assets/nft/img/Green.jpg',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': '8px'
        }
    }, {
        id: "2",
        background: 'https://cdn.nft.kred/assets/nft/img/Stars.jpg',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': '25px'
        }
    }, {
        id: "3",
        background: 'https://cdn.nft.kred/assets/nft/img/Ocean.jpg',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': 0
        }
    }, {
        id: "4",
        background: 'https://cdn.nft.kred/assets/nft/img/RedSky.jpg',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': 0,
            'box-shadow': '3px 4px 0 0px #666'

        }
    }, {
        id: "5",
        background: 'https://cdn.nft.kred/assets/nft/img/Sunny.jpg',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': '20px',
            'box-shadow': '0 0 0 2px #000000'
        }
    }, {
        id: "6",
        background: 'https://cdn.nft.kred/assets/nft/img/Dark.jpg',
        linkClass: 'rhombus-item',
        link: {
            color: '#000000',
            background: '#ffffff',
            'border-radius': 0
        }
    }];
}

function getSocialDisplayName(social) {
    return social.replace(/url$/i, '')
        .replace('applemusic', 'Apple Music')
        .replace('imdb', 'IMDb')
        .replace('empirekred', 'Empire.Kred');
}


export {
    timeDifference,
    isBetweenTimes,
    dayDifference,
    getExpiryString,
    getDateString,
    getShortDateString,
    getShortDayDateString,
    getYearString,
    getDateTimeString,
    convertLocalToUTC,
    convertUTCToLocal,
    expiryCheck,
    getTimestring,
    getExpMonthDay,
    formatAMPM,
    fullTimeString,
    isToday,
    isYesterday,

    numberWithCommas,

    objectifyForm,
    upload,
    updateUser,
    capitalise,
    formatDisplayName,
    getFirstName,
    getAvatar,
    getQueryParam,
    getAllQueryParamsAsObject,
    getIPInfo,
    round,

    checkIfMobile,
    checkIfiOSWebKitWebView,
    isInIframe,
    checkBrowser,
    iOS,
    macOS,

    formatStringJoin,

    getShortUrl,
    formatVCard,

    getProfileUrl,
    getWalletProfileUrl,

    addScript,

    extractHostname,

    kFormatter,

    getDefaultTemplates,

    getSocialDisplayName
};
