import React from 'react';
import ReactDOM from 'react-dom';

import {TabNavigation} from "./TabNavigation";

export function NavBar() {
    return ReactDOM.createPortal(
        <TabNavigation/>,
        document.getElementById('nav-tab')
    );
}