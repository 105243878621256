import _, {filter, last} from 'underscore';

import {getToken, getUser, getUserID, user} from './auth';
import {createProfileType} from "./branding";
import {domainFind} from './grab';

const grabApiUrl = window.grabApiUrl;

function requests(method, url, data, callback) {
    const request = new Promise((resolve, reject) => {
        $.ajax({
            type: method,
            url: `${url}`,
            data: data,
            dataType: 'json',
            crossDomain: true
        }).done(function (response) {
            var message = response.message[0],
                payload = response.payload[0].data;
            if (!!message && message.isError) {
                return reject(message.text);
            }
            return resolve(payload);
        }).fail(function (response) {
            response = response.responseJSON;
            var message = response.message[0],
                payload = response.payload[0].data;
            if (!!message && message.isError) {
                return reject(message.text);
            }
            return resolve(payload);
        });
    });

    if (callback) {
        return request.then(p => callback(null, p), e => callback(e));
    }

    return request;
}

function checkDomain(domainName, callback) {
    return domainFind(domainName.replace(/www\./, '').replace(/\.kred$/, '') + '.kred', callback);
}

function checkDomains(domainName, callback) {
    return requests('GET', 'https://controlpanel.kred/api/domain/checkDomains', {
        __data: JSON.stringify({
            domainName: domainName.replace(/www\./, '')
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function userUpdate(userId, bio, data, email, callback) {
    var userEntity = {
        id: userId,
        bio: _.extend(bio, {__ns: 'bio'}),
        data: data,
        // email: email,
        __ns: 'user'
    };

    if (!!email) {
        userEntity.email = email;
    }

    return requests('POST', 'https://app.nft.kred/profile/postapi', {
        domain: 'ep.jdr.ceo',
        api: '/api/user/edit',
        '__data': JSON.stringify({
            userEntity
        }),
        'connect_sid': true,
        'token': getToken()
    }, function (error, user) {
        if (error) {
            return callback();
        }
        return callback(null, user);
    });
}

function userDirectionalLinked(type, userId, callback) {
    callback = last(arguments);
    return requests('GET', 'https://controlpanel.kred/api/user/directed_identities', {
        '__data': JSON.stringify({
            userId: userId || '',
        }),
        'connect_sid': true,
        'token': getToken()
    }, function (error, users) {
        if (error) {
            return callback();
        }
        var handles = filter(users, function (obj) {
            return (type ? obj.domain === type : (obj.domain === 'twitter.com' || obj.domain === 'empire.kred'));
        });

        if (!!type) {
            return callback(null, handles && handles[0] || {});
        }
        return callback(null, users);
    });
}


function kredSubscription(data, callback) {
    /*
    * isPrereg,
        isPremium,
        domain: domain,
        * amount: paidPrice,
         token: stripeToken.id,
         isMonthlyVerify: paymentMethod === 'stripeMonthly'
         * isLinkKred
    * */
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/payment/kredSubscription',
        '__data': JSON.stringify({
            isLinkKred: true,
            ...data
        }),
        'connect_sid': true,
        'token': getToken(),
    }, callback);
}

function stripeCoupon(coupon, callback) {
    var user = getUser();
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/coupon',
        '__data': JSON.stringify({
            coupon: coupon
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function getMyKredDomains(callback) {
    return requests('GET', 'https://controlpanel.kred/api/domain/getMyKredDomains', {
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function getMyKredPreviewDomains(callback) {
    return requests('GET', 'https://controlpanel.kred/api/domain/getMyKredPreviewDomains', {
        'connect_sid': true,
        'token': getToken()
    }, callback);
}


function checkDomainAvailability(domain, callback) {
    return requests('GET', 'https://claim.ceo/api/domain/checkAvailability', {
        '__data': JSON.stringify({
            domains: [domain.toLowerCase()]
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function suggestSld(sld, tld, callback) {
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/domain/suggestSld',
        '__data': JSON.stringify({
            sld,
            tld
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}


function registerKredDomain(domain, stripeSubscription, isBYO, callback) {
    callback = last(arguments);
    var data = {
        domainName: domain,
        stripeSubscription: stripeSubscription,
        home: createProfileType() || 'links',
        source: window.branding && window.branding.name,
    };

    if (!!isBYO) {
        data.byo = 1;
    }

    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/purchase/kreddomain',
        '__data': JSON.stringify(data),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function updateDomain(domainId, data, callback) {
    return requests('POST', 'https://claim.ceo/api/domain/updateDomain', {
        '__data': JSON.stringify({
            domainEntity: {
                id: domainId,
                data: data
            }
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function setAppDNS(domain, callback) {
    return requests('POST', 'https://app.nft.kred/profile/postapi', {
        domain: 'claim.ceo',
        api: '/api/dns',
        '__data': JSON.stringify({
            domain: domain,
            dnsList: {
                HostName1: domain + '.',
                RecordType1: 'CNAME',
                Address1: 'www.' + domain + '.',
                HostName2: 'www.' + domain + '.',
                RecordType2: 'A',
                Address2: '192.155.223.110',
            }
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function leaderboardGet(callback) {
    return requests('GET', 'https://controlpanel.kred/api/leaderboard', {
        'connect_sid': true,
        'token': getToken()
    }, function (error, leaderboards) {
        if (error) {
            return callback(error);
        }
        return callback(null, _.filter(leaderboards, function (leaderboard) {
            return leaderboard.user && leaderboard.user['$id'] && leaderboard.user['$id']['$oid'] === getUserID();
        }));
    });
}

function leaderboardSave(data, callback) {
    return requests('GET', 'https://controlpanel.kred/api/leaderboard/save', {
        '__data': JSON.stringify({
            leaderboard: data
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function getMetaDetails(link, callback) {
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'ep.jdr.ceo',
        api: '/api/meta',
        '__data': JSON.stringify({
            link
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function domainLinks(domain, callback) {
    $.ajax({
        url: 'https://api.grab.live/grab/find',
        data: {
            visibility: 'hidden',
            domain: domain,
            token: getToken()
        }
    }).done(function (results) {
        if (results && results.grabs && results.grabs.length) {
            return callback(null, results.grabs[0]);
        }

        return requests('GET', 'https://app.nft.kred/profile/api', {
            'connect_sid': true,
            'token': getToken(),
            'token1': getToken(),
            domain: 'ep.jdr.ceo',
            api: '/api/grab/domainLinks',
            '__data': JSON.stringify({
                grab: domain
            }),
        }, callback);
    });
}

function messages(domain, callback) {
    $.ajax({
        url: 'https://api.grab.live/grab/messages',
        data: {
            id: domain,
            token: getToken(),
            count: 50,
            types: 'post,message'
        }
    }).done(function (results) {
        return callback(null, results && results.messages || []);
    });
}

function post(data, callback) {
    /**
     * id: grab.id,
     subject: data.title,
     text: data.description,
     media: data.image,
     private: priv,
     data: {
				type: 'link',
				link: data.link,
				textcolor: data.textcolor !== '#000000' ? data.textcolor : '',
				buttoncolor: data.buttoncolor !== '#FFFFFF' ? data.buttoncolor : '',
				animation: data.animation,
				nsfw: nsfw
			}
     * **/
    return requests('GET', 'https://app.nft.kred/profile/api', {
        'connect_sid': true,
        'token1': getToken(),
        'token': getToken(),
        domain: 'ep.jdr.ceo',
        api: '/api/grab/post',
        '__data': JSON.stringify(data)
    }, callback);
}

function deletePost(data, callback) {
    /**
     * {network: network, messageId: messageId}
     * **/
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'ep.jdr.ceo',
        api: '/api/widget/remove',
        '__data': JSON.stringify(data),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function likeUnlikePost(data, callback) {
    /**
     * {liked: true/false, messageId: messageId}
     * **/
    if (!!data.liked) {
        return requests('GET', 'https://app.nft.kred/profile/api', {
            domain: 'ep.jdr.ceo',
            api: '/api/widget/unlike',
            '__data': JSON.stringify({
                messageId: data.messageId
            }),
            'connect_sid': true,
            'token': getToken()
        }, callback);
    }
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'ep.jdr.ceo',
        api: '/api/widget/like',
        '__data': JSON.stringify({
            messageId: data.messageId
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function cancelStripeSubscription(stripeSubscription, callback) {
    return requests('GET', 'https://controlpanel.kred/api/deals/cancelStripeSubscription', {
        '__data': JSON.stringify({
            stripeSubscription
        }),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}


function getCampaigns(domainName, callback) {
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/campaign/getAll',
        '__data': JSON.stringify({
            domain: domainName
        }),
        'connect_sid': true,
        'token': getToken(),
    }, callback);
}

function createCampaign(domainName, campaignName, data, callback) {
    /*
        name: DOMAINNAME.CAMPAIGNNAME
        data: {
            campaign: String
         * 	source: String
         * 	medium: String
         * 	content: String
         * 	term: String
        }
    * */
    return requests('GET', 'https://app.nft.kred/profile/api', {
        'connect_sid': true,
        'token': getToken(),
        domain: 'claim.ceo',
        api: '/api/campaign/save',
        '__data': JSON.stringify({
            name: [domainName, campaignName, new Date().getTime()].join('.'),
            data: {
                domain: domainName,
                ...data
            }
        })
    }, callback);
}

function deleteCampaign(id, callback) {
    return requests('POST', 'https://app.nft.kred/profile/postapi', {
        domain: 'claim.ceo',
        api: '/api/campaign',
        '__data': JSON.stringify({
            id
        }),
        'connect_sid': true,
        'token': getToken(),
    }, callback);
}

function sendGrid(data, callback) {
    // data: {template, to, subject, data}
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/sendGrid/user',
        '__data': JSON.stringify(data),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

function sendGridviaEmail(data, callback) {
    // data: {template, to, subject, data}
    return requests('GET', 'https://app.nft.kred/profile/api', {
        domain: 'claim.ceo',
        api: '/api/sendGrid/custom',
        '__data': JSON.stringify(data),
        'connect_sid': true,
        'token': getToken()
    }, callback);
}

export {
    checkDomain,
    checkDomains,
    userUpdate,

    userDirectionalLinked,

    kredSubscription,

    stripeCoupon,

    getMyKredDomains,
    getMyKredPreviewDomains,

    checkDomainAvailability,
    suggestSld,
    registerKredDomain,
    updateDomain,
    setAppDNS,

    leaderboardGet,
    leaderboardSave,

    getMetaDetails,

    domainLinks,
    messages,
    post,
    deletePost,
    likeUnlikePost,

    cancelStripeSubscription,

    getCampaigns,
    createCampaign,
    deleteCampaign,

    sendGrid,
    sendGridviaEmail
};
