import React, { Component } from 'react';
import { Route } from 'react-router-dom';

export function AsyncRoute({ promise, render, ...props }) {
	return (
		<Route
			{...props}
			render={data => {
				return (
					<AsyncComponent
						key={props.path || ''}
						promise={promise}
						render={result => render(data, result)}
					/>
				);
			}}
		/>
	);
}

class AsyncComponent extends Component {
	state = {};
	mounted = false;

	async componentWillMount() {
		this.mounted = true;

		try {
			const promiseResult = await this.props.promise();

			if (this.mounted) {
				this.setState({ promiseResult });
			}
		} catch (error) {
			console.error(error);
			if (this.mounted) {
				this.setState({ error });
			}
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		const { render } = this.props;
		const { promiseResult, error } = this.state;

		if (typeof promiseResult !== 'undefined') {
			return render(promiseResult);
		}

		if (error) {
			return (
				<p className="text-center" style={{ margin: '10vh 0' }}>
					Failed to load page. Please try again.
				</p>
			);
		}

		return (
			<p className="text-center" style={{ margin: '10vh 0' }}>
				<i className="fas fa-spinner fa-spin" />
			</p>
		);
	}
}
