import React from 'react';
import _ from 'underscore';
import {isUserLoggedIn} from '../js/auth';
import {history} from '../js/history';
import {capitalise, checkIfMobile, getQueryParam} from "../js/helpers";
import async from "async";
import {getMyKredDomains} from "../js/sos";
import {deals, dealSummary, getClients, getClientToken, getVisitorStats} from "../js/grab";

class TabNavigation extends React.Component {
    constructor(props) {
        super(props);

        var manageProfile = localStorage.getItem('manageProfiles') && JSON.parse(localStorage.getItem('manageProfiles')) || [],
            showProfile = !!isUserLoggedIn() ? location.pathname.split('/')[2] : '';

        this.state = {
            manageProfiles: _.contains(manageProfile, showProfile) ? manageProfile : _.compact(manageProfile.concat(showProfile)),
            showProfile: showProfile || '',
            hasDomains: !!window.hasDomains || localStorage.getItem('apps'),
            total: 0,
            dealSummary: {}
        };
    }

    componentDidMount() {
        var _this = this;

        var setupFlow = getQueryParam(location.href, 'setup');

        if (setupFlow) {
            localStorage.setItem('setup', setupFlow);
        }

        $(document.body).on('tabNavigation:update', () => {
            _this.setState({
                hasDomains: !!window.hasDomains
            });
            _this.renderNavCount();
        });

        $(document.body).on('tabNavigation:openTab', (event, profile) => {
            const {manageProfiles} = _this.state;

            history.push('/manage/' + profile);
            _this.setState({
                manageProfiles: _.compact(_.uniq(manageProfiles.concat(profile))),
                showProfile: profile
            });
            localStorage.setItem('manageProfiles', JSON.stringify(this.state.manageProfiles))
        });

        $(document.body).on('tabNavigation:closeTab', (event, profile) => {
            const {manageProfiles} = _this.state;
            if (profile === 'new') {
                localStorage.removeItem('account');
                localStorage.removeItem('apps');
                localStorage.removeItem('links');
                localStorage.removeItem('profile');
                $('body').addClass('isSimple-step');
            }
            _this.setState({
                manageProfiles: _.without(manageProfiles, profile),
                showProfile: ''
            });
            localStorage.setItem('manageProfiles', JSON.stringify(this.state.manageProfiles))
        });

        this.renderNavCount();
    }

    renderNavCount() {
        var _this = this;

        async.auto({
            dealSummary: function (next) {
                return dealSummary(next);
            },
            clientDeals: ['dealSummary', function (res, next) {
                if (res.dealSummary && res.dealSummary.IsAgency) {
                    //Get the clients then get the deals
                    return getClients(function (error, clients) {
                        if (error) {
                            return next(error);
                        }
                        var clientDeals = [];
                        async.each(clients, function (client, cb) {
                            return getClientToken(client.user, function (err, token) {
                                if (err) {
                                    return cb(err);
                                }
                                return deals(token, function (err, deals) {
                                    if (err) {
                                        return cb(err);
                                    }
                                    clientDeals.push(deals);
                                    return cb(null, deals);
                                });
                                return cb();
                            });
                        }, function (error) {
                            if (error) {
                                return next(error);
                            }
                            return next(null, _.flatten(clientDeals));
                        });
                    });
                }
                return next();
            }],
            deals: function (next) {
                return deals('', next);
            }
        }, function (error, results) {

            if (error) {
                return _this.setState({
                    total: 0
                });
            }
            var dealSummary = results.dealSummary,
                clientDeals =results.clientDeals || [],
                deals = results.deals,
                domainsList = _.uniq(_.sortBy(_.pluck(_.filter(_.map(_.compact(deals.concat(clientDeals)), function (deal) {
                    if (deal && deal.domain && !!deal.domain.user) {
                        deal.domain.user = deal.user;
                    }
                    return deal;
                }), function (deal) {
                    return !_.isEmpty(deal.domain) &&
                        deal.domain.data && deal.domain.data.tld && deal.domain.data.tld.match(/^kred$/i);
                }), 'domain'), 'updated'), 'id').reverse() || [];

            _this.setState({
                total: domainsList.length || 0,
                dealSummary
            });

            if (!!domainsList.length && domainsList.length >= dealSummary.Footprints) {
            }
        });
    }

    onClose(event, profile) {
        event.preventDefault();
        event.stopPropagation();
        var _this = this;
        const {manageProfiles, showProfile} = this.state;

        _this.setState({
            manageProfiles: _.without(manageProfiles, profile),
            showProfile: profile === showProfile ? '' : showProfile
        });
        localStorage.setItem('manageProfiles', JSON.stringify(this.state.manageProfiles));
        if (profile === showProfile || _.isEmpty(this.state.manageProfiles)) {
            history.push('/');
        }
        if (profile === 'new') {
            localStorage.removeItem('account');
            localStorage.removeItem('apps');
            localStorage.removeItem('links');
            localStorage.removeItem('profile');
            $('body').addClass('isSimple-step');
        }
    }

    render() {
        const {
            manageProfiles,
            showProfile,
            hasDomains,
            total,
            dealSummary
        } = this.state;
        var isOnManage = location.pathname === '/' || location.pathname.match(/manage$/);

        return (
            <NavTabs>
                {!!isUserLoggedIn() && (!!hasDomains || manageProfiles) ?
                    <NavTab
                        path="/"
                        active={isOnManage}
                        title="Go to Manage"
                    >Footprints{!!total ? <span className="ml-1 badge">{total}/{dealSummary.Footprints}</span> : null}
                    </NavTab>
                    : null}
                {checkIfMobile() && _.uniq(_.without(manageProfiles, 'new')).length ?
                    <li className="nav-item">
                        <a className={"nav-link dropdown-toggle text-capitalize" + (!isOnManage ? ' active' : '')}
                           id="manageDropdown"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {capitalise(location.pathname.split('/')[2] || showProfile)}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="manageDropdown">
                            {_.uniq(manageProfiles).map(profile => {
                                var path = '/manage/' + profile;
                                return (<a className="dropdown-item" href={path}
                                           onClick={e => {
                                               e.preventDefault();
                                               history.push(path);
                                           }}>{capitalise(profile)}</a>);
                            })}
                        </div>
                    </li>
                    : _.uniq(_.without(manageProfiles, 'new')).map(profile => {
                        var path = '/manage/' + profile;

                        return (<NavTab
                            path={path}
                            active={!isOnManage && !!(location.pathname.split('/')[2]).match(profile)}
                            title={"Manage " + profile}
                            profile={profile}
                            closeable={true}
                            onClose={this.onClose.bind(this)}
                        >
                            <span className="text-capitalize">{capitalise(profile)}</span>
                        </NavTab>)
                    })}
                {!!isUserLoggedIn() && !!total ?
                    <NavTab
                        path="/manage/new"
                        active={!!(location.pathname && location.pathname.split('/') && location.pathname.split('/')[2] || '').match('new')}
                        title="Create New Footprint"
                    ><i className="fas fa-plus"></i> New Footprint</NavTab>
                    : null}
                {!!isUserLoggedIn() && !!total ?
                    <NavTab
                        path="https://www.link.kred/previews"
                        title="Create New Preview"
                    ><i className="fas fa-plus"></i> New Preview</NavTab>
                    : null}
            </NavTabs>
        );
    }
}

class NavTabs extends React.Component {
    render() {
        const {className} = this.props;

        return (
            <ul className={`tab-navigation nav nav-tabs ${className || ''}`} role="tablist">
                {this.props.children}
            </ul>
        );
    }
}

class NavTab extends React.Component {
    render() {
        const {active, path, title, children, profile, closeable, onClose} = this.props;

        return (
            <li className="nav-item"
                role="tab"
                aria-selected={active}
            >
                <a
                    href={path}
                    onClick={e => {
                        e.preventDefault();
                        if (!!path.match(/^http/)) {
                            return window.open(path);
                        }
                        history.push(path);
                    }}
                    title={title}
                    className={active ? 'nav-link active' : 'nav-link'}
                >
                    {children}
                    {!!closeable ?
                        <i className="fas fa-times ml-2" onClick={(event) => onClose(event, profile)}></i> : null}
                </a>
            </li>
        );
    }
}

export {TabNavigation, NavTabs, NavTab};
