import { request } from './request';

export const client_id = '5334ed7eb7725d5b8c9f03ed';

const loginHub = window.loginHub || 'https://login.peoplebrowsr.com';
const claimsMiddleware =  window.claimsMiddleware || 'https://claim.peoplebrowsr.com';

export function authenticate(code) {
	return ajax(loginHub + '/authenticate/dotceo', {
		client_id,
		code
	}).then(res => {
		localStorage.setItem('sessionid', res.sessionid);
		return res.code;
	});
}

export function authenticateGuest() {
	return ajax(loginHub + '/authenticate/dotceo', {
		client_id,
		email: 'guest@dotceo',
		password: 'guest'
	}).then(res => {
		localStorage.setItem('sessionid', res.sessionid);
		return res.code;
	});
}

export function getCode() {
	var data = {
		client_id: client_id,
		sessionid: localStorage.getItem('sessionid'),
		onlogout: encodeURI(
			'http://' +
				location.hostname +
				'/account/logout?preauth=' +
				generateUUID()
		)
	};

	return ajax(loginHub + '/check/dotceo', data).then(
		function(check) {
			if (!check.code) {
				return;
			}
			return check.code;
		}
	);
}
export function getToken(code) {
	return ajax(loginHub + '/token/dotceo', {
		client_id,
		code
	}).then(res => res.access_token);
}

export function connectTwitterAccount() {
	var connect;

	connect = window.open(
		claimsMiddleware + '/link/dotceo/twitter?client_id=5334ed7eb7725d5b8c9f03ed&auth=' +
			encodeURIComponent(window.token) +
			'&redirect_uri=https://app.coin.kred/nonav/transferaccount',
		'_blank',
		'height=600,width=600,status=yes,toolbar=no,menubar=no,location=no'
	);
	connect.focus();
}
export function connectFacebookAccount() {
	var connect;

	connect = window.open(
		claimsMiddleware + '/link/dotceo/facebook?client_id=5334ed7eb7725d5b8c9f03ed&auth=' +
			encodeURIComponent(window.token) +
			'&redirect_uri=https://app.coin.kred/nonav/transferaccount',
		'_blank',
		'height=600,width=600,status=yes,toolbar=no,menubar=no,location=no'
	);
	connect.focus();
}

export function checkToken() {
	return getLoginCode();
}

function getLoginCode() {
	// If there's an __auth param - log them in!
	const authCode = getParameterByName('__auth');

	if (authCode) {
		if (authCode === 'guest') {
			return new Promise((resolve, reject) => {
				return resolve('guest@dotceo:guest');
			});
			// return authenticateGuest().then(code => getToken(code)).catch(() => getLoginCodeFromServer());
		}
		return authenticate(authCode).then(code => getToken(code)).catch(() => getLoginCodeFromServer());
	} else {
		return getLoginCodeFromServer();
	}
}

function getLoginCodeFromServer() {
	return checkLoginServer();
}

function checkLoginServer() {
	var data = {
		client_id: client_id,
		response_type: 'token',
		sessionid: localStorage.getItem('sessionid'),
		onlogout: encodeURI(
			'http://' +
				location.hostname +
				'/account/logout?preauth=' +
				generateUUID()
		)
	};

	if (window.retryCheck === 1) {
		data.retry = 1;
	}

	return ajax(loginHub + '/check/dotceo', data).then(
		function(check) {
			window.retryCheck = 0;
			if (!check.access_token) {
				return;
			}
			localStorage.setItem('sessionid', check.sessionid);
			localStorage.setItem('badcredentials', check.bad_credentials);
			return check.access_token;
		}
	).catch(function () {
		return;
	});
}

function ajax(url, data) {
	return request('GET', url, data, null, {
		xhrFields: {
			withCredentials: true
		}
	});
}

export function generateUUID() {
	var d = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
		c
	) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});

	return uuid;
}

function getParameterByName(name, url) {
	if (!url) url = location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);

	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
