import {memoize} from 'async';
import {last, first, initial, find, filter, isEmpty, extend} from 'underscore';
import {checkLogin, getToken} from 'universal-navbar/auth';
import {request} from 'universal-navbar/request';
import {extractHostname} from "./helpers";
import {instagramSilo} from "./grab";

const memoizedUser = memoize(user);

window.grabApiUrl = 'https://api.grab.live';

if (!!location.hostname.match(/test\.app\.crypto\.kred/)) {
    window.grabApiUrl = 'https://test.grab.live';
    window.testApp = 1;
}

var url = window.grabApiUrl,
    loggedInUser = false;

// check this when the module first loads, after that it'll be cached
function checkLoggedInUser(callback) {
    checkLogin(accessToken => {
        if (!accessToken) {
            return callback(null, false);
        }

        memoizedUser(function (error, user) {
            if (error) {
                callback(error);
            } else {
                loggedInUser = user;
                callback(null, user);
            }
        });
    });
}

// synchronously return cached logged in user
// it should be already cached before first render, and never changes
function getUser() {
    return loggedInUser || {};
}

// for testing purposes
function setUser(user) {
    loggedInUser = user;
}

function getUserID() {
    return loggedInUser && loggedInUser.id || false;
}

function getHome() {
    return loggedInUser && loggedInUser.home || '';
}

function isUserLoggedIn() {
    return !!getUserID() && getUserID() !== "547f8d53b7725d797d0a563d";
}

function requireLogin() {
    if (!isUserLoggedIn()) {
        $('.domainPurchase-modal').modal('hide');
        $('.signup-modal').modal({
            show: true,
            backdrop: 'static'
        });

        return true;
    }

    return false;
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function formatUser(res) {
    var user = res.user;
    user.home =
        (find((res.domains || []).concat(res.profiles || []), function (profile) {
            return profile && profile.match(/.kred$/) && !profile.match(/\.c\.kred$/);
        }) || find((res.domains || []).concat(res.profiles || []), function (profile) {
            return profile && profile.match(/\.c\.kred$/);
        }) || res.home || '').replace(/.kred/, '.Kred');

    user.profiles = res.profiles || [];
    user.verified = res.verified;

    return user;
}

function user(userId, callback) {
    callback = last(arguments);
    userId = first(initial(arguments)) || null;
    request(
        'GET',
        [url, '/user/home'].join(''),
        {
            mode: getToken() === 'guest@dotceo:guest' ? 'quick' : '',
            user: userId ? userId : '',
            token: getToken(),
        },
        function (error, res) {
            if (error || res.error) {
                return callback(error || res.error);
            }
            var user = res.user;
            if (
                !user ||
                user.id === '552766adb7725d22e5b02910'
            ) {
                return callback(null, {id: false});
            }
            return callback(null, formatUser(res));
        }
    );
}

function userSetup(callback) {
    checkLoggedInUser(function (err, user) {
        if (!user) {
            return callback();
        }
        request(
            'POST',
            [url, '/user/setup'].join(''),
            {
                token: getToken()
            },
            callback
        );
    });
}

function userLinked(userId, callback) {
    callback = last(arguments);
    userId = first(initial(arguments)) || null;
    request(
        'GET',
        [url, '/account/linked'].join(''),
        {
            user: userId ? userId : '',
            token: getToken()
        },
        function (error, res) {
            if (error || res.error) {
                return callback(null, []);
            }
            return callback(null, res);
        }
    );
}


function mailRelay(data, callback) {
    //sending an email to the logged in user from a nice email address
    $.ajax({
        type: 'POST',
        url: window.claimsMiddleware + '/mail_relay/dotceo',
        data: extend(data, {
            token: getToken()
        })
    }).done(function (response) {
        return callback();
    });
}

function mailCoinOwner(data, callback) {
    //sending an email to the coin's owner from a nice address
    $.ajax({
        type: 'POST',
        url: window.claimsMiddleware + '/mail_owner/dotceo',
        data: extend(data, {
            token: getToken()
        })
    }).done(function (response) {
        return callback();
    });
}

function mailCoinMinter(data, callback) {
    //sending an email to the coin's creator from a nice address
    $.ajax({
        type: 'POST',
        url: window.claimsMiddleware + '/mail_creator/dotceo',
        data: extend(data, {
            token: getToken()
        })
    }).done(function (response) {
        return callback();
    });
}

function registerDomain(domain, callback) {
    // register a .kred domain and do the associated socialOS setup for that domain
    // /quick_kred/dotceo
    // needs a user_id and a domain (try the user's EK ticker first)
    var data = {
        domain: domain,
        token: getToken(),
    };

    if (window.testApp) {
        data.test = 1;
    }

    request(
        'GET',
        window.claimsMiddleware + '/quick_kred/dotceo',
        data,
        function (error, res) {
            if (error) {
                return callback(error.message || error.error);
            }
            return callback(null, res);
        }
    );
}

function newClient(data, callback) {
    //https://claim.peoplebrowsr.com/admin_register/dotceo
    //send "name", "email" and "password"
    request(
        'GET',
        window.claimsMiddleware + '/admin_register/dotceo',
        {
            ...data,
            token: getToken()
        },
        function (error, data) {
            if (error) {
                return callback(error.message || error.error);
            }

            return user(data.admin && data.admin.user, callback);
        }
    );
}

function socialFinder(username, callback) {
    //https://claim.peoplebrowsr.com/social_find/dotceo?username=artee
    request(
        'GET',
        window.claimsMiddleware + '/social_find/dotceo',
        {
            username: username,
            token: getToken()
        },
        function (error, data) {
            if (error) {
                return callback(error.message || error.error);
            }

            return callback(null, data);
        }
    );
}

function getLinktreeOrEKData(handle, service, callback) {
    request(
        'GET',
        window.claimsMiddleware + '/link_preview/dotceo',
        {
            service,
            username: handle,
            token: getToken()
        },
        function (error, previewData) {
            if (error) {
                return callback(error.message || error.error);
            }
            var socialsRegex = /twitter|facebook|instagram|linkedin|youtu|spotify|tiktok|whatsapp|music\.apple|snapchat|soundcloud|twitch|pinterest|tumblr|medium|reddit|mailto|paypal|venmon|kofi|mewe|parler|pateron/,
                socials = {};

            _.each(previewData.socials, function (social) {
                if (!social.match(socialsRegex)) {
                    return;
                }
                var platform = social.match(socialsRegex)[0];

                if (platform === 'mailto') {
                    return socials['email'] = social.replace('mailto:', '');
                }
                return socials[platform === 'youtu' ? 'youtubeUrl' :
                    platform === 'music.apple' ? 'applemusic' : platform] = social;
            });

            // var socialLinks = _.filter(_.map(previewData.socials, function (social) {
            //     if (!!social.match(socialsRegex)) {
            //         return {};
            //     }
            //     return {
            //         link: social,
            //         text: extractHostname(social),
            //     };
            // }), function (link) {
            //     return !_.isEmpty(link);
            // });

            return callback(null, _.extend(previewData, {
                avatar: service === 'linktree' ? 'https://api.grab.live/avatars/linktree/?domain=https%3A%2F%2Flinktr.ee%2F' + handle + '&url=' + encodeURIComponent(previewData.avatar) : previewData.avatar,
                fullname: previewData.name || '',
                backgroundColor: previewData['background-color'],
                socials,
                links: _.first(_.map(previewData.links, function (link, key) {
                    return {
                        id: key,
                        image: link.thumbnail,
                        link: link.link,
                        title: link.text,
                        buttoncolor: previewData['button-color'],
                        textcolor: previewData['button-text']
                    }
                }), 5)
            }));
        }
    );
}

async function getInstagramData(handle, callback) {
    var user;
    try {
        const userInfoSource = await $.get('https://www.instagram.com/' + handle + '/?__a=1');
        user = userInfoSource && userInfoSource.graphql && userInfoSource.graphql.user;
        // userInfoSource.data contains the HTML from Axios
        instagramSilo({
            name: handle,
            foreign_id: user.id,
            data: userInfoSource,
            token: getToken()
        }, function () {
        });
    } catch (e) {
        console.error('Unable to retrieve photos. Reason: ' + e.toString())
        return callback(null, {});
    }

    var data = {
            fullname: user.full_name,
            avatar: user.profile_pic_url,
            background: '',
        },
        extData,
        links = user.edge_owner_to_timeline_media && user.edge_owner_to_timeline_media.edges;

    if (!!user.biography) {
        extData = {
            image: user.profile_pic_url_hd,
            link: user.external_url,
            title: user.biography || user.external_url
        };
    }

    return callback(null, {
        ...data,
        socials: {
            instagram: 'https://instagram.com/' + handle
        },
        links: _.compact([extData].concat(_.map(_.first(_.sortBy(links, function (link) {
            var total = link.node.edge_liked_by.count + link.node.edge_media_to_comment.count;
            return -total;
        }), 5), function (link, key) {
            return {
                id: key,
                image: link.node && link.node.thumbnail_src,
                link: 'https://instagram.com/p/' + (link.node && link.node.shortcode),
                title: link.node && link.node.edge_media_to_caption && link.node.edge_media_to_caption.edges &&
                    link.node.edge_media_to_caption.edges[0] && link.node.edge_media_to_caption.edges[0].node &&
                    link.node.edge_media_to_caption.edges[0].node.text || link.node && link.node.accessibility_caption,
            }
        })))
    });
}

function notifyClient(domain, callback) {
    /*
    * send 'domain', 'channel' and 'channelurl' to notify_client in the claims API,
    * using the token of the agency. If everything checks out, it will issue a password f
    * or the domain owners (ie the client) account, and send them the welcome email.
    * */
    request(
        'GET',
        window.claimsMiddleware + '/notify_client/dotceo',
        {
            domain: domain,
            channel: window.branding && window.branding.tldCaps,
            channelurl: window.branding && window.branding.name,
            token: getToken()
        },
        function (error, data) {
            if (error) {
                return callback(error.message || error.error);
            }

            return callback(null, data);
        }
    );
}

function claimPreviewDomain(domain, previewDomain, callback) {
    /*
    *   previewname : the name of the preview domain
        domainname :  the domain to clone the information into
        token: a token for the new domain owner
    * */

    if (!previewDomain || !domain) {
        return callback();
    }

    request(
        'GET',
        window.claimsMiddleware + '/claim_preview/dotceo',
        {
            previewname: previewDomain,
            domainname: domain,
            token: getToken()
        },
        function (error, data) {
            console.log('claimPreviewDomain', error, data);
            if (error) {
                return callback(error.message || error.error);
            }

            return callback();
        }
    );
}

export {
    checkLogin,
    getParameterByName,
    getToken,
    formatUser,
    memoizedUser as user,
    userSetup,
    userLinked,

    checkLoggedInUser,
    getUser,
    setUser,
    getUserID,
    getHome,
    isUserLoggedIn,
    requireLogin,

    mailRelay,
    mailCoinOwner,
    mailCoinMinter,

    registerDomain,

    newClient,

    socialFinder,
    getLinktreeOrEKData,
    getInstagramData,

    notifyClient,

    claimPreviewDomain
};
