import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();

//This is to help scroll through a collection's coin profiles (jdr.ceo/life)
history.listen(location => {
    $('.tooltip.show').remove();

    if (!!location.pathname.match('manage/new')) {
        $('body').addClass('isSimple-step');
    }
});
