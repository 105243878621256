// NOTE: @babel/preset-env with useBuiltIns:entry (see package.json) will
// replace this import with polyfills specific to what we're using
// see https://babeljs.io/docs/en/babel-preset-env#usebuiltins
import "@babel/polyfill";

import React from 'react';
import ReactDOM from 'react-dom';

import './css/style.css';
// main app
import App from './containers/App';

import {checkLoggedInUser} from './js/auth';

window.hasDomains = 0;

const appRoot = document.getElementById('appFootprint');
console.log('footprint - index.js start', performance.now());

checkLoggedInUser(function (error, user) {
    console.log('ck - checkLoggedInUser', performance.now());
    if (error) {
        $(document.body).trigger('messenger:show', ['error', error]);
    }
    ReactDOM.render(<App/>, appRoot);
});

