import {getQueryParam} from "./helpers";

export function minFootprint() {
    var plan = getQueryParam(location.href, 'plan') || localStorage.getItem('plan');
    //Minimum  amount of footprints to use this flow - used for that hub.kred will default to community manager plan
    return plan === 'communityagency' ? 10
        : plan === 'linkkredbusiness' ? 5
        : plan === 'linkkredplus' ? 3
        : window.branding && window.branding.footprint_min_footprint_count && parseInt(window.branding.footprint_min_footprint_count) || 0;
}

export function getKredFreePlan() {
    return '57eb06f878b4f10e2bb58993';
}

export function getLinkKredFreePlan() {
    return '5f5ed7bdd2bfa8507d6e6a68';
}

export function getLinkKredPlusPlan() {
    return '5f5ed811d2bfa8507d6e6a69';
}

export function getLinkKredPlusYearlyPlan() {
    return '5f30e4add2bfa82710f85773';
}

export function getLinkKredFreeStripePlan() {
    return 'linkkred_free';
}

export function getLinkKredPlusStripePlan() {
    return 'linkkred_plus';
}

export function getLinkKredPlusYearlyStripePlan() {
    return 'linkkred_plus_yearly';
}

export function getLinkKredBusinessPlan() {
    return '600e2431d2bfa84f0b8c18a6';
}

export function getLinkKredBusinessStripePlan() {
    return 'LinkKredBusiness';
}

export function getCommunityManagerPlan() {
    return '5f30e4add2bfa82710f85773';
}

export function getCommunityManagerStripePlan() {
    return 'CommunityManager';
}

export function getDigitalAgencyPlan() {
    return '5f5eeb5dd2bfa8507d6e6a6b';
}

export function getDigitalAgencyStripePlan() {
    return 'DigitalAgency';
}


export function getPremiumAgencyPlan() {
    return '5f5eeb9cd2bfa8507d6e6a6c';
}

export function getPremiumAgencyStripePlan() {
    return 'PremiumAgency';
}

export function getPlanName(stripePlan) {
    switch (stripePlan) {
        case getLinkKredFreePlan():
            return 'Link.Kred Free';
        case getLinkKredPlusPlan():
            return 'Link.Kred Plus';
        case getCommunityManagerStripePlan():
            return 'Community Manager';
        case getDigitalAgencyStripePlan():
            return 'Digital Agency';
        case getPremiumAgencyStripePlan():
            return 'Premium Agency';
        default:
            break;
    }
}


export function getTldCaps() {
    return window.branding && window.branding.tldCaps;
}

export function getBrandName() {
    return window.branding && window.branding.name;
}

export function getStartedHeader() {
    var header = window.branding && window.branding.footprint_getstarted_header;
    switch (localStorage.getItem('setup')) {
        case 'domain':
            header = 'Choose your Kred Influencer Domain';
            break;
        case 'contacts':
            header = 'Rank your Contacts by Influence';
            break;
        case 'posts':
            header = 'Organize your Posts by Topic';
            break;
        default:
            break;
    }
    return header;
}

export function getStartedSubheader() {
    var header = window.branding && window.branding.footprint_getstarted_subheader;
    switch (localStorage.getItem('setup')) {
        case 'domain':
            header = 'and showcase your entire Social Footprint';
            break;
        case 'contacts':
            header = 'and take control of your Social Footprint';
            break;
        case 'posts':
            header = 'and take control of your Social Footprint';
            break;
        default:
            break;
    }
    return header;
}

export function getManageHeader() {
    return window.branding && window.branding.footprint_manage_header;
}

export function getManageSubheader() {
    return window.branding && window.branding.footprint_manage_subheader;
}

export function createProfileType() {
    return window.branding && window.branding.defaultHomeType || 'links';
}

export function createProfiles() {
    return !!(window.branding && window.branding.footprint_create_profile);
}

export function createPosts() {
    return !!(window.branding && window.branding.footprint_create_posts);
}

export function createContacts() {
    return !!(window.branding && window.branding.footprint_create_contacts);
}

export function createLeaderboard() {
    return !!(window.branding && window.branding.footprint_create_leaderboard);
}

export function showLoginFirst() {
    return !!(window.branding && window.branding.footprint_showLoginFirst);
}

