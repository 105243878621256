import { request } from './request';
import { getToken } from '../auth';

export function getUserHome() {
	return api('GET', '/user/home', {
		mode: window.token === 'guest@dotceo:guest' ? 'quick' : ''
	});
}

// https://docs.socialos.io/reference

export function api(method, path, property, params, callback) {
	const url = window.grabApiUrl || 'https://api.nft.kred';

	if (
		typeof property === 'object' &&
		(!params || typeof params === 'function')
	) {
		// if you leave out 'property'
		callback = params;
		params = property;
		property = undefined;
	} else if (typeof params === 'function' && !callback) {
		// if you leave out 'params'
		callback = params;
		params = undefined;
	}

	return new Promise((resolve, reject) =>
		request(
			method,
			[url, path].join(''),
			{
				...params,
				token: getToken()
			},
			(error, res) => {
				if (error || res && res.error) {
					reject(error || res.error);
				} else if (property && res && property in res) {
					resolve(res[property]);
				} else {
					resolve(res);
				}
			}
		)
	);
}

export const grabRequest = api;

export function loadGrabVisitPixel(token) {
	const pixel = new Image();
	pixel.src = `https://api.nft.kred/visit/image?token=${token}`;
}
